import { ChangeEvent, FC, useCallback, useMemo, useState, KeyboardEvent, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { RootBox, BadgeImg, BadgeImgWrapper } from './Style';
import GraphCard from '../../../components/graph/graph-card/GraphCard';
import Table from '../../../../../ui/general/table/Table';
import { IInsightsOrganizationBadgesVM } from '../../../../../interfaces/views/IInsightsOrganizationBadgesVM';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import { useInsightsOrganizationBadgesStateValue } from '../../../../../contexts/InsightsOrganizationBadgesContext';
import Filters from '../../../../../ui/filters/filters/Filters';
import {
    FiltersWrapper,
    GraphCardToolbarFormControl,
    GraphCardToolbarFormControlLabel,
    GraphCardToolbarRadio,
    GraphCardToolbarRadioGroup,
    GraphCardToolbarValueBox,
    SearchInputWrapper,
    StyledSearchInput,
    TableFiltersWrapper,
    TableItemCountBox,
    TableItemCountTypography,
    TableWrapper
} from '../../../../../pages/insights/Style';
import { FiltersProvider } from '../../../../../contexts/FiltersContext';
import { useInsightsStateValue } from '../../../../../contexts/InsightsContext';
import { EInsightsMode } from '../../../../../interfaces/enums/EInsightsMode';
import {
    makeInsightsOrganizationalSingleBadge,
    makeInsightsOrganizationalUsersBadges,
    routes
} from '../../../../../pages/routes';
import { useCrumbsStateValue } from '../../../../../contexts/CrumbsContext';
import CheckboxUncheckedIcon from '../../../../../assets/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '../../../../../assets/icons/CheckboxCheckedIcon';
import { IOrganizationalBadgeVM } from '../../../../../interfaces/views/IOrganizationalBadgeVM';
import { createUrl } from '../../../../../utils/createUrl';
import { Order } from '../../../../../hooks/useTable';
import sortPossibleUndefinedStrings from '../../../../../utils/sortPossibleUndefinedStrings';
import fetchImageErrorCallback from '../../../../../utils/fetchImageErrorCallback';
import { mapInsightsGraphScoreDates } from '../../../../../utils/insightsGraphDataSelector';
import { useInsightsOrganizationStateValue } from '../../../../../contexts/InsightsOrganizationContext';

interface IUserTableHeader {
    image: string;
    displayName: string;
    department: string;
    region: string;
    shared: number;
    claimed: number;
    readyToClaim: number;
    needAttention: number;
}

interface IBadgeTableHeader {
    image: string;
    badgeName: string;
    shared: number;
    claimed: number;
    readyToClaim: number;
    needsAttentionBadges: number;
}

enum EGraphOption {
    USERS = 'Users',
    BADGES = 'Badges'
}

const OrganizationalBadgesTab: FC = () => {
    const { t } = useTranslation();
    const {
        scoreLines,
        latestDateData,
        onFilterValueChange,
        filterCategories,
        isBadgesDataLoading,
        isOrganizationalBadgesDataLoading,
        searchUsersText,
        setSearchUsersText,
        badgesTableData,
        searchBadgesText,
        setSearchBadgesText
    } = useInsightsOrganizationBadgesStateValue();
    const { selectedTimespanOption, handleSelectedTimespanOptionChange } = useInsightsOrganizationStateValue();

    const [selectedGraphOption, setSelectedGraphOption] = useState<EGraphOption>(EGraphOption.USERS);
    const graphOptionsRef = useRef<EGraphOption[]>([EGraphOption.USERS, EGraphOption.BADGES]);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const { setUserName, changeMode, setUserId, setSelectedBadgelName } = useInsightsStateValue();
    const navigate = useNavigate();
    const { appendCrumb, replaceLatestCrumb } = useCrumbsStateValue();

    useEffect(() => {
        const hashFragment = location.hash;
        if (hashFragment && hashFragment !== '') {
            const preparedHashFragment = hashFragment.replace('#', '');
            setSelectedGraphOption(
                (preparedHashFragment.charAt(0).toUpperCase() + preparedHashFragment.slice(1)) as EGraphOption
            );
        }
    }, [location]);

    useEffect(() => {
        if (selectedGraphOption)
            replaceLatestCrumb({
                name: selectedGraphOption === EGraphOption.BADGES ? 'Badges' : 'Users',
                pathname: `${routes.INSIGHTS_ORGANIZATIONAL_BADGES}#${selectedGraphOption.toString().toLowerCase()}`
            });
        navigate(`${location.pathname}#${selectedGraphOption.toLowerCase()}`, { replace: true });
    }, [selectedGraphOption]);

    const usersHeadCells: ITableHeadCell<IUserTableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true
        },
        {
            id: 'displayName',
            label: 'Display Name',
            minWidth: '130px'
        },
        {
            id: 'department',
            label: 'Department'
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center'
        },
        {
            id: 'shared',
            label: 'Shared',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'claimed',
            label: 'Claimed',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'readyToClaim',
            label: 'Ready to Claim',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'needAttention',
            label: 'Need Attention',
            align: 'left',
            disablePadding: true,
            numeric: true
        }
    ];

    const badgesHeadCells: ITableHeadCell<IBadgeTableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true,
            customRender: (value: string) => {
                return (
                    <BadgeImgWrapper>
                        <BadgeImg src={createUrl(value)?.generatedUrl} onError={fetchImageErrorCallback} />
                    </BadgeImgWrapper>
                );
            }
        },
        {
            id: 'badgeName',
            label: 'Badge Name',
            minWidth: '130px',
            customSort: (a: IOrganizationalBadgeVM, b: IOrganizationalBadgeVM, order: Order) => {
                return sortPossibleUndefinedStrings(
                    `${a.outcomeName} - ${a.level}`,
                    `${b.outcomeName} - ${b.level}`,
                    order
                );
            }
        },
        {
            id: 'shared',
            label: 'Shared',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'claimed',
            label: 'Claimed',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'readyToClaim',
            label: 'Ready to Claim',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'needsAttentionBadges',
            label: 'Need Attention',
            align: 'left',
            disablePadding: true,
            numeric: true
        }
    ];

    const customToolbar = useMemo(() => {
        return (
            <TableItemCountBox>
                <TableItemCountTypography variant='caption'>
                    {selectedGraphOption === EGraphOption.USERS
                        ? `${latestDateData.length} Users`
                        : `${badgesTableData?.length || 0} Badges`}
                </TableItemCountTypography>
            </TableItemCountBox>
        );
    }, [latestDateData, badgesTableData, selectedGraphOption]);

    const scoreLinesValuesMemo = useMemo(() => {
        if (isBadgesDataLoading || !scoreLines) return undefined;
        return scoreLines;
    }, [scoreLines, isBadgesDataLoading]);

    const xAxisMemo = useMemo(() => {
        if (isBadgesDataLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            return mapInsightsGraphScoreDates(scoreLines[0].scores);
        }
        return [];
    }, [scoreLines, selectedTimespanOption, isBadgesDataLoading]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
            />
        );
    }, [onFilterValueChange, filterCategories]);

    const onUsersTableRowClick = useCallback((_: any, row: IInsightsOrganizationBadgesVM) => {
        setUserName(row.displayName);
        setUserId(row.userId);
        const route = makeInsightsOrganizationalUsersBadges(row.userId);
        changeMode(EInsightsMode.ORG_PERSONAL);
        appendCrumb({
            name: 'Insights',
            pathname: route,
            callback: () => {
                changeMode(EInsightsMode.STANDARD);
            }
        });
        navigate(route);
    }, []);

    const onBadgesTableRowClick = useCallback((_: any, row: IOrganizationalBadgeVM) => {
        setSelectedBadgelName(`${row.outcomeName} - ${row.level}`);
        const route = makeInsightsOrganizationalSingleBadge(row.outcomeId, row.level);
        changeMode(EInsightsMode.ORG_PERSONAL);
        appendCrumb({
            name: 'Insights',
            pathname: route,
            callback: () => {
                changeMode(EInsightsMode.STANDARD);
            }
        });
        navigate(route);
    }, []);

    const onRadioOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            handleRadioButtonChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setSelectedGraphOption(value as EGraphOption);
    };

    const customGraphCardToolbar = useMemo(() => {
        return (
            <GraphCardToolbarFormControl>
                <GraphCardToolbarRadioGroup
                    value={selectedGraphOption}
                    id='tab-mode'
                    name={`controlled-radio-buttons-group`}
                >
                    {graphOptionsRef.current.map((value) => {
                        return (
                            <GraphCardToolbarValueBox key={value} variant='body2'>
                                <GraphCardToolbarFormControlLabel
                                    value={value}
                                    control={
                                        <GraphCardToolbarRadio
                                            onChange={handleRadioButtonChange}
                                            icon={<CheckboxUncheckedIcon />}
                                            checkedIcon={<CheckboxCheckedIcon />}
                                            onKeyDown={onRadioOptionKeyDown}
                                            tabIndex={0}
                                            checked={selectedGraphOption === value}
                                        />
                                    }
                                    label={value}
                                    color='primary'
                                />
                            </GraphCardToolbarValueBox>
                        );
                    })}
                </GraphCardToolbarRadioGroup>
            </GraphCardToolbarFormControl>
        );
    }, [selectedGraphOption]);

    const tableMemo = useMemo(() => {
        switch (selectedGraphOption) {
            case EGraphOption.USERS:
                return (
                    <Table<IInsightsOrganizationBadgesVM, IUserTableHeader>
                        headCells={usersHeadCells}
                        data={latestDateData}
                        propertyKeys={usersHeadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=''
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isBadgesDataLoading}
                        customToolbar={customToolbar}
                        isImageColumnPresent
                        imageColumnName='userId'
                        onRowClick={onUsersTableRowClick}
                        initialOrderBy='displayName'
                        userImageInfoColumn='displayName'
                        userImageInfoColumnPosition={isMdDown ? 'bottom' : 'right'}
                    />
                );
            case EGraphOption.BADGES:
                return (
                    <Table<IOrganizationalBadgeVM, IBadgeTableHeader>
                        headCells={badgesHeadCells}
                        data={badgesTableData || []}
                        propertyKeys={badgesHeadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=''
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isOrganizationalBadgesDataLoading}
                        customToolbar={customToolbar}
                        // isImageColumnPresent
                        // imageColumnName="userId"
                        onRowClick={onBadgesTableRowClick}
                        initialOrderBy='badgeName'
                        customMediaColumnName='image'
                    />
                );
            default:
                return <></>;
        }
    }, [
        badgesHeadCells,
        usersHeadCells,
        selectedGraphOption,
        latestDateData,
        customToolbar,
        isBadgesDataLoading,
        isOrganizationalBadgesDataLoading
    ]);

    const searchUsersMemo = useMemo(() => {
        return (
            <SearchInputWrapper className='search-box'>
                <StyledSearchInput
                    placeholder='Search Users'
                    value={searchUsersText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (setSearchUsersText) setSearchUsersText(e.target.value);
                    }}
                />
            </SearchInputWrapper>
        );
    }, [searchUsersText]);

    const searchBadgesMemo = useMemo(() => {
        return (
            <SearchInputWrapper className='search-box'>
                <StyledSearchInput
                    placeholder='Search Badges'
                    value={searchBadgesText || ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (setSearchBadgesText) setSearchBadgesText(e.target.value);
                    }}
                />
            </SearchInputWrapper>
        );
    }, [searchBadgesText]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <GraphCard
                    scoreLines={scoreLinesValuesMemo}
                    xAxis={xAxisMemo}
                    handleTimelineChange={handleSelectedTimespanOptionChange}
                    translations={{ graphCardTitle: t('insights.organization.badges.graphCardTitle') }}
                    customToolbar={customGraphCardToolbar}
                    isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                    isLoading={isBadgesDataLoading || scoreLinesValuesMemo === undefined}
                    selectedTimespanOption={selectedTimespanOption}
                />
                {selectedGraphOption === EGraphOption.USERS ? searchUsersMemo : searchBadgesMemo}
                <TableFiltersWrapper>
                    <TableWrapper>{tableMemo}</TableWrapper>
                    {!isLgDown && <FiltersWrapper>{filtersMemo}</FiltersWrapper>}
                </TableFiltersWrapper>
            </FiltersProvider>
        </RootBox>
    );
};

export default OrganizationalBadgesTab;
