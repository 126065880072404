import { FC, useEffect, useState, useRef, useMemo } from 'react';
import { Input, SelectChangeEvent } from '@mui/material';
import Graph from '../Graph';
import {
    RootPaper,
    GraphWrapper,
    CardHeader,
    StyledFormControl,
    StyledSelect,
    StyledMenuItem,
    MenuItemTitleSpan,
    GraphCardHeaderTitleTypography
} from './Style';
import { IScoreLine } from '../../../../../interfaces/IScoreLine';
import { EAxisType } from '../../../../../interfaces/enums/EAxisType';
import { EGraphCardSelect } from '../../../../../interfaces/enums/EGraphCardSelect';
import { LongDottedTextSpan } from '../../../../../css/CommonComponents';
import UpArrowIcon from '../../../../../assets/icons/UpArrowIcon';
import { useNavigationStateValue } from '../../../../../contexts/NavigationContext';

interface IProps {
    translations: {
        graphCardTitle?: string;
        graphCardHeaderTitle?: string;
    };
    scoreLines?: IScoreLine[];
    xAxis?: string[];
    handleTimelineChange?: (value: string | string[]) => void;
    graphMaxValue?: number;
    customToolbar?: React.ReactNode;
    isDataEmpty?: boolean;
    benchmarkStartValue?: number;
    benchmarkEndValue?: number;
    children?: (
        dimension: { width: string | number },
        isDataEmpty: boolean,
        isLoading: boolean,
        isNavVisible: boolean
    ) => React.ReactNode;
    graphLegendLabel?: string;
    timelineFilterMenuOptions?: { key: EGraphCardSelect; name: string }[];
    isLoading?: boolean;
    selectedTimespanOption: EGraphCardSelect | undefined;
}

const GraphCard: FC<IProps> = ({
    translations,
    scoreLines,
    xAxis,
    handleTimelineChange,
    graphMaxValue,
    customToolbar,
    isDataEmpty,
    benchmarkStartValue,
    benchmarkEndValue,
    children,
    graphLegendLabel,
    timelineFilterMenuOptions,
    isLoading,
    selectedTimespanOption
}) => {
    const [graphWidth, setGraphWidth] = useState<number>(600);
    const graphWrapperRef = useRef<any | undefined>(undefined);
    const filterMenuItemsRef = useRef([
        {
            key: EGraphCardSelect.MONTH_1,
            name: '1-Month'
        },
        {
            key: EGraphCardSelect.MONTH_2,
            name: '2-Months'
        },
        {
            key: EGraphCardSelect.MONTH_3,
            name: '3-Months'
        },
        {
            key: EGraphCardSelect.MONTH_6,
            name: '6-Months'
        },
        {
            key: EGraphCardSelect.MONTH_9,
            name: '9-Months'
        },
        {
            key: EGraphCardSelect.YEAR_WITH_WEEKS,
            name: '1-Year'
        }
    ]);
    const [selectedFilterKey, setSelectedFilterKey] = useState<string | undefined>();
    const { showNav } = useNavigationStateValue();

    useEffect(() => {
        setSelectedFilterKey(selectedTimespanOption);
    }, [selectedTimespanOption]);

    useEffect(() => {
        if (timelineFilterMenuOptions) {
            filterMenuItemsRef.current = timelineFilterMenuOptions;
        }
    }, [timelineFilterMenuOptions]);

    useEffect(() => {
        const updateWindowWidth = () => {
            setTimeout(
                () => {
                    if (window.innerWidth < 1200 && graphWrapperRef.current)
                        setGraphWidth(showNav ? window.innerWidth - 230 : window.innerWidth - 120);
                    else setGraphWidth(showNav ? 900 : 790);
                },
                !graphWrapperRef.current ? 300 : 0
            );
        };

        window.addEventListener('resize', updateWindowWidth);
        updateWindowWidth();

        return () => window.removeEventListener('resize', updateWindowWidth);
    }, [showNav]);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value }
        } = event;
        if (Array.isArray(value)) {
            setSelectedFilterKey(value[0]);
        } else setSelectedFilterKey(value);
        if (handleTimelineChange) {
            handleTimelineChange(value);
        }
    };

    const xAxisTypeMemo = useMemo(() => {
        switch (selectedFilterKey) {
            case EGraphCardSelect.MONTH_1:
            case EGraphCardSelect.MONTH_2:
            case EGraphCardSelect.MONTH_3:
                return EAxisType.TIME;
            case EGraphCardSelect.MONTH_6:
            case EGraphCardSelect.MONTH_9:
            case EGraphCardSelect.YEAR_WITH_WEEKS:
                return EAxisType.MONTHLY_WITH_WEEKS;
            default:
                return EAxisType.TIME;
        }
    }, [selectedFilterKey]);

    return (
        <RootPaper elevation={1}>
            <CardHeader
                isCustomToolbar={!!customToolbar}
                isColumnDirectionNeeded={!!translations?.graphCardHeaderTitle}
            >
                {customToolbar || <span id='graph-card-title'>{translations.graphCardTitle || ''}</span>}
                {translations?.graphCardHeaderTitle && (
                    <GraphCardHeaderTitleTypography id='graph-card-title' variant='body1'>
                        <LongDottedTextSpan>{translations?.graphCardHeaderTitle}</LongDottedTextSpan>
                    </GraphCardHeaderTitleTypography>
                )}
                <StyledFormControl>
                    {selectedFilterKey && (
                        <StyledSelect
                            placeholder='Filter'
                            value={selectedFilterKey ? [selectedFilterKey] : undefined}
                            onChange={handleChange}
                            input={<Input disableUnderline />}
                            renderValue={(selected) => {
                                if (selected?.length === 1)
                                    return filterMenuItemsRef.current.find(
                                        (filterItem) => filterItem.key === selected[0].toString()
                                    )?.name;
                                return undefined;
                            }}
                            IconComponent={UpArrowIcon}
                        >
                            {filterMenuItemsRef.current.map((filterMenuItem) => {
                                return (
                                    <StyledMenuItem
                                        sx={{ height: 32, paddingLeft: '4px' }}
                                        key={filterMenuItem.key}
                                        value={filterMenuItem.key}
                                    >
                                        <MenuItemTitleSpan>{filterMenuItem.name}</MenuItemTitleSpan>
                                    </StyledMenuItem>
                                );
                            })}
                        </StyledSelect>
                    )}
                </StyledFormControl>
            </CardHeader>
            <GraphWrapper ref={graphWrapperRef}>
                {(children &&
                    xAxisTypeMemo &&
                    selectedTimespanOption &&
                    children({ width: graphWidth }, !!isDataEmpty, !!isLoading, showNav)) || (
                    <Graph
                        scoreLines={scoreLines}
                        xAxis={xAxis}
                        yMaxValue={graphMaxValue}
                        xAxisType={xAxisTypeMemo}
                        dimension={{ width: graphWidth }}
                        isDataEmpty={isDataEmpty}
                        benchmarkEndValue={benchmarkEndValue}
                        benchmarkStartValue={benchmarkStartValue}
                        graphLegendLabel={graphLegendLabel}
                        isLoading={isLoading}
                    />
                )}
            </GraphWrapper>
        </RootPaper>
    );
};

export default GraphCard;
