import {
    FC,
    PropsWithChildren,
    useMemo,
    useRef,
    useState,
    KeyboardEvent,
    useCallback,
    ChangeEvent,
    useEffect
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import GraphCard from '../../../components/graph/graph-card/GraphCard';
import Table from '../../../../../ui/general/table/Table';
import {
    EUsersScoreLineType,
    useInsightsOrganizationalUsersStateValue
} from '../../../../../contexts/InsightsOrganizationalUsersContext';
import { ITableHeadCell } from '../../../../../interfaces/ITableHeaderCell';
import {
    GraphCardToolbarFormControl,
    GraphCardToolbarFormControlLabel,
    GraphCardToolbarRadio,
    GraphCardToolbarRadioGroup,
    GraphCardToolbarValueBox,
    TableFiltersWrapper,
    TableWrapper,
    FiltersWrapper,
    RootBox,
    TableItemCountBox,
    TableItemCountTypography,
    SearchInputWrapper,
    StyledSearchInput
} from '../../../../../pages/insights/Style';
import CheckboxUncheckedIcon from '../../../../../assets/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '../../../../../assets/icons/CheckboxCheckedIcon';
import { IScoreLine } from '../../../../../interfaces/IScoreLine';
import Filters from '../../../../../ui/filters/filters/Filters';
import { IInsightsOrganizationUsersCompetenceVM } from '../../../../../interfaces/views/IInsightsOrganizationUsersCompetenceVM';
import { FiltersProvider } from '../../../../../contexts/FiltersContext';
import {
    makeInsightsOrganizationalUsersOutcome,
    makeInsightsOrganizationalUsersSkill,
    routes
} from '../../../../../pages/routes';
import { useInsightsStateValue } from '../../../../../contexts/InsightsContext';
import { EInsightsMode } from '../../../../../interfaces/enums/EInsightsMode';
import { useCrumbsStateValue } from '../../../../../contexts/CrumbsContext';
import { mapInsightsGraphScoreDates } from '../../../../../utils/insightsGraphDataSelector';
import { useInsightsOrganizationStateValue } from '../../../../../contexts/InsightsOrganizationContext';

interface ISkillsTableHeader {
    image: string;
    displayName: string;
    department: string;
    region: string;
    skillsAssigned: number;
    skillsInProgress: number;
    skillsAttained: number;
    skillsNeedAttention: number;
}

interface IOutcomesTableHeader {
    image: string;
    displayName: string;
    department: string;
    region: string;
    outcomesAssigned: number;
    outcomesInProgress: number;
    outcomesAttained: number;
    outcomesNeedAttention: number;
}

enum EGraphOption {
    SKILLS = 'Skills',
    OUTCOMES = 'Outcomes'
}

const OrganizationalUsersTab: FC<PropsWithChildren> = () => {
    const { t } = useTranslation();
    const {
        scoreLines,
        latestDateData,
        onFilterValueChange,
        filterCategories,
        isUsersCompetenceDataLoading,
        searchText,
        setSearchText
    } = useInsightsOrganizationalUsersStateValue();

    const { selectedTimespanOption, handleSelectedTimespanOptionChange } = useInsightsOrganizationStateValue();

    const [selectedGraphOption, setSelectedGraphOption] = useState<EGraphOption>(EGraphOption.SKILLS);
    const graphOptionsRef = useRef<EGraphOption[]>([EGraphOption.SKILLS, EGraphOption.OUTCOMES]);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const { changeMode, setUserName } = useInsightsStateValue();
    const { appendCrumb, replaceLatestCrumb } = useCrumbsStateValue();

    useEffect(() => {
        const hashFragment = location.hash;
        if (hashFragment && hashFragment !== '') {
            const preparedHashFragment = hashFragment.replace('#', '');
            setSelectedGraphOption(
                (preparedHashFragment.charAt(0).toUpperCase() + preparedHashFragment.slice(1)) as EGraphOption
            );
        }
    }, [location]);

    useEffect(() => {
        if (selectedGraphOption)
            replaceLatestCrumb({
                name: selectedGraphOption === EGraphOption.OUTCOMES ? 'Org. Outcomes' : 'Org. Skills',
                pathname: `${routes.INSIGHTS_ORGANIZATIONAL_USERS}#${selectedGraphOption.toString().toLowerCase()}`
            });
        navigate(`${location.pathname}#${selectedGraphOption.toLowerCase()}`, { replace: true });
    }, [selectedGraphOption]);

    const headerOverviewSkillsRef = useRef([
        {
            colSpan: 4,
            label: ''
        },
        {
            colSpan: 4,
            label: 'Skills'
        }
    ]);
    const headerOverviewOutcomesRef = useRef([
        {
            colSpan: 4,
            label: ''
        },
        {
            colSpan: 4,
            label: 'Outcomes'
        }
    ]);

    const outcomesheadCells: ITableHeadCell<IOutcomesTableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true
        },
        {
            id: 'displayName',
            label: 'Display Name',
            minWidth: '130px'
        },
        {
            id: 'department',
            label: 'Department'
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center'
        },
        {
            id: 'outcomesAssigned',
            label: 'Assigned',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'outcomesInProgress',
            label: 'In Progress',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'outcomesAttained',
            label: 'Attained',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'outcomesNeedAttention',
            label: 'Need Attention',
            align: 'left',
            disablePadding: true,
            numeric: true
        }
    ];

    const skillsHeadCells: ITableHeadCell<ISkillsTableHeader>[] = [
        {
            id: 'image',
            label: '',
            disableSort: true
        },
        {
            id: 'displayName',
            label: 'Display Name',
            minWidth: '130px'
        },
        {
            id: 'department',
            label: 'Department'
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center'
        },
        {
            id: 'skillsAssigned',
            label: 'Assigned',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'skillsInProgress',
            label: 'In Progress',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'skillsAttained',
            label: 'Attained',
            align: 'left',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'skillsNeedAttention',
            label: 'Need Attention',
            align: 'left',
            disablePadding: true,
            numeric: true
        }
    ];

    const customToolbar = useMemo(() => {
        return (
            <TableItemCountBox>
                {latestDateData && (
                    <TableItemCountTypography variant='caption'>{`${latestDateData.length} Users`}</TableItemCountTypography>
                )}
            </TableItemCountBox>
        );
    }, [latestDateData]);

    const filterScoreLinesByType = (scoreLine: IScoreLine) => {
        if (selectedGraphOption === EGraphOption.SKILLS) return scoreLine.type === EUsersScoreLineType.SKILLS;
        else if (selectedGraphOption === EGraphOption.OUTCOMES) return scoreLine.type === EUsersScoreLineType.OUTCOMES;
        return false;
    };

    const scoreLinesValuesMemo = useMemo(() => {
        if (isUsersCompetenceDataLoading || !scoreLines) return undefined;
        return scoreLines.filter(filterScoreLinesByType);
    }, [scoreLines, isUsersCompetenceDataLoading, selectedGraphOption]);

    const xAxisMemo = useMemo(() => {
        if (isUsersCompetenceDataLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            return mapInsightsGraphScoreDates(scoreLines.filter(filterScoreLinesByType)[0]?.scores || []);
        }
        return [];
    }, [scoreLines, selectedTimespanOption, isUsersCompetenceDataLoading, selectedGraphOption]);

    const onRadioOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            handleRadioButtonChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setSelectedGraphOption(value as EGraphOption);
    };

    const customGraphCardToolbar = useMemo(() => {
        return (
            <GraphCardToolbarFormControl>
                <GraphCardToolbarRadioGroup
                    value={selectedGraphOption}
                    id='tab-mode'
                    name={`controlled-radio-buttons-group`}
                >
                    {graphOptionsRef.current.map((value) => {
                        return (
                            <GraphCardToolbarValueBox key={value} variant='body2'>
                                <GraphCardToolbarFormControlLabel
                                    value={value}
                                    control={
                                        <GraphCardToolbarRadio
                                            onChange={handleRadioButtonChange}
                                            icon={<CheckboxUncheckedIcon />}
                                            checkedIcon={<CheckboxCheckedIcon />}
                                            onKeyDown={onRadioOptionKeyDown}
                                            tabIndex={0}
                                            checked={selectedGraphOption === value}
                                        />
                                    }
                                    label={value}
                                    color='primary'
                                />
                            </GraphCardToolbarValueBox>
                        );
                    })}
                </GraphCardToolbarRadioGroup>
            </GraphCardToolbarFormControl>
        );
    }, [selectedGraphOption]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
            />
        );
    }, [onFilterValueChange, filterCategories]);

    const onTableRowClick = useCallback(
        (_: any, row: IInsightsOrganizationUsersCompetenceVM) => {
            setUserName(row.displayName);
            changeMode(EInsightsMode.ORG_PERSONAL);
            let route = '';
            if (selectedGraphOption === EGraphOption.SKILLS) route = makeInsightsOrganizationalUsersSkill(row.userId);
            else if (selectedGraphOption === EGraphOption.OUTCOMES)
                route = makeInsightsOrganizationalUsersOutcome(row.userId);
            appendCrumb({
                name: 'Org Insights',
                pathname: route,
                callback: () => {
                    changeMode(EInsightsMode.STANDARD);
                }
            });
            navigate(route);
        },
        [selectedGraphOption]
    );

    const tableMemo = useMemo(() => {
        switch (selectedGraphOption) {
            case EGraphOption.OUTCOMES:
                return (
                    <Table<IInsightsOrganizationUsersCompetenceVM, IOutcomesTableHeader>
                        headCells={outcomesheadCells}
                        data={latestDateData}
                        propertyKeys={outcomesheadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=''
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isUsersCompetenceDataLoading}
                        customToolbar={customToolbar}
                        isImageColumnPresent
                        imageColumnName='userId'
                        headerOverviewConfig={headerOverviewOutcomesRef.current}
                        onRowClick={onTableRowClick}
                        initialOrderBy='displayName'
                        userImageInfoColumn='displayName'
                        userImageInfoColumnPosition={isMdDown ? 'bottom' : 'right'}
                    />
                );
            case EGraphOption.SKILLS:
                return (
                    <Table<IInsightsOrganizationUsersCompetenceVM, ISkillsTableHeader>
                        headCells={skillsHeadCells}
                        data={latestDateData}
                        propertyKeys={skillsHeadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=''
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isUsersCompetenceDataLoading}
                        customToolbar={customToolbar}
                        isImageColumnPresent
                        imageColumnName='userId'
                        headerOverviewConfig={headerOverviewSkillsRef.current}
                        onRowClick={onTableRowClick}
                        initialOrderBy='displayName'
                        userImageInfoColumn='displayName'
                        userImageInfoColumnPosition={isMdDown ? 'bottom' : 'right'}
                    />
                );
            default:
                return <></>;
        }
    }, [selectedGraphOption, latestDateData, customToolbar, isUsersCompetenceDataLoading]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                <GraphCard
                    scoreLines={scoreLinesValuesMemo}
                    xAxis={xAxisMemo}
                    handleTimelineChange={handleSelectedTimespanOptionChange}
                    translations={{ graphCardTitle: t('insights.personal.skills.graphCardTitle') }}
                    customToolbar={customGraphCardToolbar}
                    isDataEmpty={scoreLinesValuesMemo && scoreLinesValuesMemo.length === 0}
                    isLoading={isUsersCompetenceDataLoading || scoreLinesValuesMemo === undefined}
                    selectedTimespanOption={selectedTimespanOption}
                />
                <SearchInputWrapper className='search-box'>
                    <StyledSearchInput
                        placeholder='Search Users'
                        value={searchText || ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (setSearchText) setSearchText(e.target.value);
                        }}
                    />
                </SearchInputWrapper>
                <TableFiltersWrapper>
                    <TableWrapper>{tableMemo}</TableWrapper>
                    {!isLgDown && <FiltersWrapper>{filtersMemo}</FiltersWrapper>}
                </TableFiltersWrapper>
            </FiltersProvider>
        </RootBox>
    );
};

export default OrganizationalUsersTab;
