import { FC, useMemo, useRef, KeyboardEvent, useEffect, useCallback, ChangeEvent } from 'react';
import { useNavigate } from 'react-router';
import { useMediaQuery, useTheme } from '@mui/material';
import {
    Dot,
    FiltersWrapper,
    GraphCardToolbarFormControl,
    GraphCardToolbarFormControlLabel,
    GraphCardToolbarRadio,
    GraphCardToolbarRadioGroup,
    GraphCardToolbarValueBox,
    RootBox,
    SearchInputWrapper,
    StyledSearchInput,
    TableFiltersWrapper,
    TableItemCountBox,
    TableItemCountTypography,
    TableWrapper
} from '../../../../Style';
import { ITableHeadCell } from '../../../../../../interfaces/ITableHeaderCell';
import {
    EOrgOutcomesGraphOption,
    useInsightsOrganizationalOutcomesDetailsStateValue
} from '../../../../../../contexts/InsightsOrganizationOutcomesDetailsContext';
import Filters from '../../../../../../ui/filters/filters/Filters';
import Table from '../../../../../../ui/general/table/Table';
import GraphCard from '../../../../../../pages/insights/components/graph/graph-card/GraphCard';
import { FiltersProvider } from '../../../../../../contexts/FiltersContext';
import CheckboxUncheckedIcon from '../../../../../../assets/icons/CheckboxUncheckedIcon';
import CheckboxCheckedIcon from '../../../../../../assets/icons/CheckboxCheckedIcon';
import { DotTableBox } from '../Style';
import { IInsightsOrganizationOutcomesUserVM } from '../../../../../../interfaces/views/IInsightsOganizationOutcomesUserVM';
import { IInsightsOrganizationOutcomesSkillVM } from '../../../../../../interfaces/views/IInsightsOrganizationOutcomesSkillVM';
import { IScoreLine } from '../../../../../../interfaces/IScoreLine';
import { useInsightsStateValue } from '../../../../../../contexts/InsightsContext';
import usePageHeaderConfig from '../../../../../../hooks/usePageHeaderConfig';
import { useCrumbsStateValue } from '../../../../../../contexts/CrumbsContext';
import { makeInsightsOutcomeDetailsRoute, makeInsightsSkillDetailsRoute } from '../../../../../../pages/routes';
import { mapInsightsGraphScoreDates } from '../../../../../../utils/insightsGraphDataSelector';
import { useInsightsOrganizationStateValue } from '../../../../../../contexts/InsightsOrganizationContext';
import { EGraphCardSelect } from '../../../../../../interfaces/enums/EGraphCardSelect';

interface IUsersTableHeader {
    image: string;
    displayName: string;
    department: string;
    region: string;
    userAchieved: number;
    skillsTotal: number;
    skillsAttained: number;
    skillsNotAttained: number;
}

interface ISkillsTableHeader {
    color: string;
    skillName: string;
    assigned: number;
    notAttained: number;
    attained: number;
    needAttention: number;
}

const OrganizationOutcomesDetails: FC = () => {
    const {
        scoreLines,
        userTableData,
        skillTableData,
        onFilterValueChange,
        filterCategories,
        skillGraphScoreLines,
        isSkillsDataLoading,
        isUsersDataLoading,
        outcomeId,
        searchText,
        setSearchText,
        selectedGraphOption,
        setSelectedGraphOption,
        changeScoreLinesInterval
    } = useInsightsOrganizationalOutcomesDetailsStateValue();
    const graphOptionsRef = useRef<EOrgOutcomesGraphOption[]>([
        EOrgOutcomesGraphOption.USERS,
        EOrgOutcomesGraphOption.SKILLS
    ]);
    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const headerOverviewUsersRef = useRef([
        {
            colSpan: 5,
            label: ''
        },
        {
            colSpan: 4,
            label: 'Skills'
        }
    ]);
    const { selectedOutcomeName, setSelectedSkillName } = useInsightsStateValue();
    const { setConfig } = usePageHeaderConfig();
    const { appendCrumb, replaceLatestCrumb } = useCrumbsStateValue();
    const navigate = useNavigate();
    const { selectedTimespanOption, handleSelectedTimespanOptionChange } = useInsightsOrganizationStateValue();

    useEffect(() => {
        const hashFragment = location.hash;
        if (hashFragment && hashFragment !== '') {
            const preparedHashFragment = hashFragment.replace('#', '');
            setSelectedGraphOption(
                (preparedHashFragment.charAt(0).toUpperCase() +
                    preparedHashFragment.slice(1)) as EOrgOutcomesGraphOption
            );
        }
    }, [location]);

    useEffect(() => {
        if (outcomeId && selectedGraphOption)
            replaceLatestCrumb({
                name: selectedGraphOption === EOrgOutcomesGraphOption.SKILLS ? 'Skill Details' : 'User Details',
                pathname: makeInsightsOutcomeDetailsRoute(outcomeId, selectedGraphOption.toLowerCase())
            });
        navigate(`${location.pathname}#${selectedGraphOption.toLowerCase()}`, { replace: true });
    }, [selectedGraphOption]);

    const handleTimelineChange = (value: string | string[]) => {
        changeScoreLinesInterval(value as EGraphCardSelect);
        handleSelectedTimespanOptionChange(value as EGraphCardSelect);
    };

    useEffect(() => {
        //TODO Fix
        if (selectedGraphOption === EOrgOutcomesGraphOption.USERS) {
            setConfig({
                isSearchVisible: true,
                searchPlaceholder: 'Search Users',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        } else {
            setConfig({
                isSearchVisible: false,
                searchPlaceholder: '',
                pageName: 'Insights',
                isLastUpdatedInfoVisible: false
            });
        }
    }, [selectedGraphOption]);

    const skillsHeadCells: ITableHeadCell<ISkillsTableHeader>[] = [
        {
            id: 'color',
            label: '',
            customRender: (value) => {
                return <Dot color={value} />;
            }
        },
        {
            id: 'skillName',
            label: 'Skill Name',
            minWidth: '130px'
        },
        {
            id: 'assigned',
            label: 'Assigned',
            align: 'center'
        },
        {
            id: 'notAttained',
            label: 'In Progress',
            align: 'center'
        },
        {
            id: 'attained',
            label: 'Attained',
            align: 'center'
        },
        {
            id: 'needAttention',
            label: 'Need Attention',
            align: 'center'
        }
    ];

    const usersHeadCells: ITableHeadCell<IUsersTableHeader>[] = [
        {
            id: 'image',
            label: ''
        },
        {
            id: 'displayName',
            label: 'Display Name',
            minWidth: '130px'
        },
        {
            id: 'department',
            label: 'Department'
        },
        {
            id: 'region',
            label: 'Region',
            align: 'center'
        },
        {
            id: 'userAchieved',
            label: 'User Level Achieved',
            minWidth: '110px',
            disablePadding: true,
            customRender: (value) => {
                if (value)
                    return (
                        <DotTableBox>
                            <Dot color='#349FEE' />
                        </DotTableBox>
                    );
                return <></>;
            }
        },
        {
            id: 'skillsTotal',
            label: 'Assigned',
            align: 'center',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'skillsAttained',
            label: 'Attained',
            align: 'center',
            disablePadding: true,
            numeric: true
        },
        {
            id: 'skillsNotAttained',
            label: 'In Progress',
            align: 'center',
            disablePadding: true,
            numeric: true,
            minWidth: '120px'
        }
    ];

    const customToolbar = useMemo(() => {
        return (
            <TableItemCountBox>
                <TableItemCountTypography variant='caption'>{`${userTableData.length} Users`}</TableItemCountTypography>
            </TableItemCountBox>
        );
    }, [userTableData]);

    const filterScoreLinesByType = (scoreLine: IScoreLine) => {
        return selectedGraphOption === scoreLine?.type;
    };

    const scoreLinesValuesUserMemo = useMemo(() => {
        if (isUsersDataLoading || !scoreLines) return undefined;
        return scoreLines.filter(filterScoreLinesByType);
    }, [scoreLines, selectedGraphOption, isUsersDataLoading]);

    const xAxisUserMemo = useMemo(() => {
        if (isUsersDataLoading || !scoreLines) return undefined;
        if (scoreLines?.length > 0) {
            const filterUserGraph = scoreLines.filter(filterScoreLinesByType);
            if (filterUserGraph.length > 1) return mapInsightsGraphScoreDates(filterUserGraph[0].scores);
        }
        return [];
    }, [scoreLines, selectedGraphOption, isUsersDataLoading]);

    const xAxisSkillMemo = useMemo(() => {
        if (isSkillsDataLoading || !skillGraphScoreLines) return undefined;
        if (skillGraphScoreLines?.length > 0) {
            const filterSkillGraph = skillGraphScoreLines.filter(filterScoreLinesByType);
            if (filterSkillGraph.length > 1) return mapInsightsGraphScoreDates(filterSkillGraph[0].scores);
        }
        return [];
    }, [skillGraphScoreLines, selectedGraphOption, isSkillsDataLoading]);

    const scoreLinesValuesSkillMemo = useMemo(() => {
        if (isSkillsDataLoading || !skillGraphScoreLines) return undefined;
        return skillGraphScoreLines.filter(filterScoreLinesByType);
    }, [skillGraphScoreLines, selectedGraphOption, isSkillsDataLoading]);

    const onRadioOptionKeyDown = (e: KeyboardEvent<HTMLButtonElement>) => {
        if (e.key === 'Enter') {
            handleRadioButtonChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        setSelectedGraphOption(value as EOrgOutcomesGraphOption);
    };

    const customGraphCardToolbar = useMemo(() => {
        return (
            <GraphCardToolbarFormControl>
                <GraphCardToolbarRadioGroup
                    value={selectedGraphOption}
                    id='tab-mode'
                    name={`controlled-radio-buttons-group`}
                >
                    {graphOptionsRef.current.map((value) => {
                        return (
                            <GraphCardToolbarValueBox key={value} variant='body2'>
                                <GraphCardToolbarFormControlLabel
                                    value={value}
                                    control={
                                        <GraphCardToolbarRadio
                                            onChange={handleRadioButtonChange}
                                            icon={<CheckboxUncheckedIcon />}
                                            checkedIcon={<CheckboxCheckedIcon />}
                                            onKeyDown={onRadioOptionKeyDown}
                                            tabIndex={0}
                                            checked={selectedGraphOption === value}
                                        />
                                    }
                                    label={value}
                                    color='primary'
                                />
                            </GraphCardToolbarValueBox>
                        );
                    })}
                </GraphCardToolbarRadioGroup>
            </GraphCardToolbarFormControl>
        );
    }, [selectedGraphOption]);

    const filtersMemo = useMemo(() => {
        return (
            <Filters
                isDisabled={false}
                handleFilterValueChange={onFilterValueChange}
                categories={filterCategories}
                isQuickFiltersSelected={false}
                dropdownMode
            />
        );
    }, [onFilterValueChange, filterCategories]);

    const handleSkillTableRowClick = useCallback((id: string, obj: IInsightsOrganizationOutcomesSkillVM) => {
        setSelectedSkillName(obj.skillName);
        if (!outcomeId) return;
        const route = makeInsightsSkillDetailsRoute(outcomeId, id);
        appendCrumb({
            name: 'Skill Insights',
            pathname: route
        });
        navigate(route);
    }, []);

    const tableMemo = useMemo(() => {
        switch (selectedGraphOption) {
            case EOrgOutcomesGraphOption.SKILLS:
                return (
                    <Table<IInsightsOrganizationOutcomesSkillVM, ISkillsTableHeader>
                        key='skill-table'
                        headCells={skillsHeadCells}
                        data={skillTableData}
                        propertyKeys={skillsHeadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=''
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isSkillsDataLoading}
                        onRowClick={handleSkillTableRowClick}
                        initialOrderBy='skillName'
                        initialOrderDirection='asc'
                        customMediaColumnName='skillName'
                        customMediaSecondaryColumns={['color']}
                    />
                );
            case EOrgOutcomesGraphOption.USERS:
                return (
                    <Table<IInsightsOrganizationOutcomesUserVM, IUsersTableHeader>
                        key='user-table'
                        headCells={usersHeadCells}
                        data={userTableData}
                        propertyKeys={usersHeadCells.map((headCell) => {
                            return headCell.id;
                        })}
                        tableTitlePlural=''
                        isFilterControlVisible={false}
                        isFilterDrawerOpen={false}
                        isLoading={isUsersDataLoading}
                        customToolbar={customToolbar}
                        isImageColumnPresent
                        imageColumnName='userId'
                        headerOverviewConfig={headerOverviewUsersRef.current}
                        initialOrderBy='displayName'
                        userImageInfoColumn='displayName'
                    />
                );
            default:
                return <></>;
        }
    }, [
        selectedGraphOption,
        skillTableData,
        userTableData,
        customToolbar,
        skillsHeadCells,
        isSkillsDataLoading,
        isUsersDataLoading
    ]);

    const graphCardUserMemo = useMemo(() => {
        return (
            <GraphCard
                scoreLines={scoreLinesValuesUserMemo}
                xAxis={xAxisUserMemo}
                handleTimelineChange={handleTimelineChange}
                translations={{
                    graphCardHeaderTitle: selectedOutcomeName
                }}
                customToolbar={customGraphCardToolbar}
                isDataEmpty={scoreLinesValuesUserMemo && scoreLinesValuesUserMemo.length === 0}
                isLoading={isUsersDataLoading || scoreLinesValuesUserMemo === undefined}
                selectedTimespanOption={selectedTimespanOption}
            />
        );
    }, [userTableData, scoreLinesValuesUserMemo, xAxisUserMemo, customGraphCardToolbar, isUsersDataLoading]);

    const graphCardSkillMemo = useMemo(() => {
        return (
            <GraphCard
                scoreLines={scoreLinesValuesSkillMemo}
                xAxis={xAxisSkillMemo}
                handleTimelineChange={handleTimelineChange}
                translations={{
                    graphCardHeaderTitle: selectedOutcomeName
                }}
                customToolbar={customGraphCardToolbar}
                isDataEmpty={scoreLinesValuesSkillMemo && scoreLinesValuesSkillMemo.length === 0}
                isLoading={isSkillsDataLoading || scoreLinesValuesSkillMemo === undefined}
                graphLegendLabel='Skill Name'
                selectedTimespanOption={selectedTimespanOption}
            />
        );
    }, [skillTableData, scoreLinesValuesSkillMemo, xAxisSkillMemo, customGraphCardToolbar, isSkillsDataLoading]);

    return (
        <RootBox>
            <FiltersProvider categories={filterCategories}>
                {selectedGraphOption === EOrgOutcomesGraphOption.USERS ? graphCardUserMemo : graphCardSkillMemo}
                {selectedGraphOption === EOrgOutcomesGraphOption.USERS && (
                    <SearchInputWrapper className='search-box'>
                        <StyledSearchInput
                            placeholder='Search Users'
                            value={searchText || ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                if (setSearchText) setSearchText(e.target.value);
                            }}
                        />
                    </SearchInputWrapper>
                )}
                <TableFiltersWrapper>
                    <TableWrapper>{tableMemo}</TableWrapper>
                    {!isLgDown && selectedGraphOption === EOrgOutcomesGraphOption.USERS && (
                        <FiltersWrapper>{filtersMemo}</FiltersWrapper>
                    )}
                </TableFiltersWrapper>
            </FiltersProvider>
        </RootBox>
    );
};

export default OrganizationOutcomesDetails;
